import React, { useState, useEffect } from "react";
import axios from "axios";
import { addproduct } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ImageGalleryTwo = () => {
  const [categories, setCategories] = useState([]);
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const getRandomProducts = (products) => {
    const product = [...products];
    return product.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomProducts = getRandomProducts(products);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/categories/getallcategory`
      );
      setCategories(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const getRandomCategory = (category) => {
    return category.sort(() => 0.5 - Math.random()).slice(0, 4);
  };

  const randomCategory = getRandomCategory(categories);


  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className="container mx-auto  lg:pl-10 lg:pr-10 pb-5 bg-gray-50 pt-5">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
        {/* Column 1 */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">Top Pick</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {randomProducts.map((product) => (
              <div
                key={product.id}
                className="group border bg-white rounded-lg  p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
              >
                 <Link to={`/products/${product.id}`}>
                <div className="">
                  <img
                    src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                    alt={product.name}
                    className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className=" text-center w-full py-2">
                    <p className="text-lg font-semibold">{product.name}</p>
                  </div>
                </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Column 2 */}
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-bold mb-4 text-gray-700">
            Top Categories
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {randomCategory.map((category) => (
              <div
                key={category.id}
                className="group border bg-white rounded-lg  p-2 pb-1 hover:shadow-xl transition-shadow duration-300"
              >
                <Link to={`/category/${category.id}`}>
                <div className="">
                  <img
                    src={`https://uvfolderking.com/admin/storage/app/public/${category.image}`}
                    alt={category.name}
                    className="w-full h-48 object-cover rounded-lg transition-transform duration-300 group-hover:scale-105"
                  />
                  <div className=" text-center w-full py-2">
                    <p className="text-lg font-semibold">{category.name}</p>
                  </div>
                </div>
                </Link>
              </div>
            ))}
          </div>
        </div>

        {/* Column 3 */}
        <div className="flex flex-col items-center justify-center bg-white shadow-lg rounded-lg p-6">
          <p className="text-xl font-medium text-gray-600 mb-6">
            Explore more categories and find what you need!
          </p>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow hover:bg-blue-700 transition duration-300">
            See More
          </button>        </div>
      </div>
    </div>
  );
};

export default ImageGalleryTwo;
