import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import image from "../../images/MYPIC.png";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "./../../../redux/action";

const UserAccount = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [userProfile, setUserProfile] = useState([]);
  const user = { image: image };
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const { Wi } = props;
  console.log(Wi);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

 
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserProfile());
  }, [dispatch]);
  console.log("the redux profile in account now  is ", userProfile);

  return (
    <div
      className={
        Wi
          ? `absolute flex flex-col pl-5 w-[-20px] sticky top-24 z-30 p-5 shadow-lg bg-white`
          : "flex flex-col pl-5 w-[250px] sticky top-24 z-30 p-5 shadow-lg bg-white"
      }
    >
      <div className="flex items-center gap-5 p-2 shadow-sm rounded-md">
        <img src={user.image} alt="User" className="w-16 h-16 rounded-full" />
        <div className="text-sm">
          <p className="text-gray-500">Hello,</p>
          <p className="font-semibold">
            {userProfile.first_name} {userProfile.last_name}
          </p>
        </div>
      </div>

      <div className="mt-5">
        <Link to="/account/myorders">
          <h2 className="text-lg font-semibold mb-3 border-b pb-2">
            MY ORDERS
          </h2>
        </Link>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">ACCOUNT SETTINGS</h2>
        <ul className="space-y-2">
          <Link to="/account/account_details">
            <li className="hover:text-blue-500 mb-2 cursor-pointer">
              Profile Information
            </li>
          </Link>
          <Link to="/account/addresses">
            <li className="hover:text-blue-500 mb-2 cursor-pointer">
              Manage Addresses
            </li>
          </Link>
          <li className="border-b pb-2 hover:text-blue-500 cursor-pointer">
            PAN Card Information
          </li>
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">PAYMENTS</h2>
        <ul className="space-y-2">
          <li className="hover:text-blue-500 cursor-pointer">Gift Cards</li>
          <li className="hover:text-blue-500 cursor-pointer">Saved UPI</li>
          <li className="border-b pb-2 hover:text-blue-500 cursor-pointer">
            Saved Cards
          </li>
        </ul>
      </div>

      <div className="mt-5">
        <h2 className="text-lg font-semibold mb-3">MY STUFF</h2>
        <ul className="space-y-2">
          <li className="hover:text-blue-500 cursor-pointer">
            My Reviews & Ratings
          </li>
          <Link to="/account/wishlist-products">
            {" "}
            <li className="hover:text-blue-500 mt-2  cursor-pointer">
              My Wishlist
            </li>
          </Link>
          <li className="border-b pb-2 hover:text-blue-500 cursor-pointer">
            My Coupons
          </li>
        </ul>
      </div>

      <div className="mt-5  text-lg border-b pb-2 font-semibold hover:text-red-500 cursor-pointer">
        LOGOUT
      </div>
    </div>
  );
};

export default UserAccount;
