import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  addproduct,
  // getCartItems,
  setUserProfile,
  userAddresses,
} from "./../../redux/action";
import { useNavigate } from "react-router-dom";

const BuyNowOrderSummary = () => {
  // Static cart items

  const [product, setBuyNow] = useState({});
  const [addresses, setAddress] = useState([]);
  const [Imagefeature, setImage] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [color, setColor] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  console.log("the address", addresses);
  console.log("payment type selected", selectedPayment);
  const [isProduct, setIsProduct] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [formdata, setFormData] = useState({
    color: "red",
    size: "L",
    configurations: [
      {
        type: "Storage",
        name: "128GB",
      },
      {
        type: "RAM",
        name: "6GB",
      },
    ],
    coupon_code: "DISCOUNT10",
    final_price: 299.99,
    payment_type: selectedPayment,
  });
  const navigate = useNavigate();
  
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  console.log(userId);
  const userProfile = useSelector((state) => state.userProfile);
  console.log("The redux profile in account now is ", userProfile);
  console.log("product", product);
  // const address = useSelector((state) => state.userAddresses);
  // console.log("The redux address is: ", address);

  const products = useSelector((state) => state.products);
  console.log("the redux products is ", products);
  const dispatch = useDispatch();

  useEffect(() => {
    const userAddresses = async () => {
      try {
        const response = await axios.get(
          `https://uvfolderking.com/suresop/api/user/shipping-addresses/user_id_${userId}`
        );

        setAddress(response.data.addresses);

        console.log("ad", response.data);
      } catch (error) {
        console.error("Error fetching user addresses:", error);
      }
    };
    userAddresses();
  }, []);

  const fetchUserData = () => async (dispatch) => {
    // await dispatch(setUserProfile());
    await dispatch(userAddresses());
  };

  // Then use a single `useEffect`
  // useEffect(() => {
  //   dispatch(fetchUserData());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);


  useEffect(() => {
    console.log("order details api ");
    const fetchBuyNow = async () => {
      try {
        const response = await axios.get(
          `https://uvfolderking.com/suresop/api/order-details/user_id_${userId} `
        );
        setBuyNow(response.data[response.data.length-1]);
        setImage(response.data[response.data.length-1].product.featured_image);
        setColor(response.data[response.data.length-1].color);
        setFinalPrice(response.data[response.data.length-1].sale_price);
        setIsProduct(true);
        console.log("the response.data", response.data[response.data.length-1]);
        const setOrderObj = {
          size: "L",
          configurations: [
            {
              type: "Storage",
              name: response.data[response.data.length-1].configuration,
            },
          ],
          final_price: response.data[response.data.length-1].sale_price,
          payment_type: selectedPayment,
        };

        // setFormData(setOrderObj);
      } catch (error) {
        console.error("Error fetching buy now data:", error);
      }
    };
    fetchBuyNow();
  }, [selectedPayment]);

  // useEffect(() => {
  //   dispatch(setUserProfile());
  // }, [dispatch]);

  // useEffect(() => {
  //   dispatch(userAddresses());
  // }, [dispatch]);

  // Calculate total price and item count based on static cart items
  // useEffect(() => {
  //   const total = cart.reduce(
  //     (acc, item) => acc + item.sale_price * item.quantity,
  //     0
  //   );
  //   const items = cart.reduce((acc, item) => acc + item.quantity, 0);
  //   setTotalPrice(total);
  //   setTotalItems(items);
  // }, []);

  // useEffect(() => {
  //   dispatch(getCartItems());
  // }, [dispatch]);

  const handleAddressSelect = (e) => {
    const addressId = e.target.value;
    setSelectedAddress(addressId);
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Function to handle placing the order
  const handlePlaceOrder = async (deleteId, vendorId, productId) => {
    console.log("order is runing ");
    console.log("Order Data:", formdata);
    try {
      if (selectedAddress && selectedPayment) {
        const response = await Promise.all([
          axios.post(
            `https://uvfolderking.com/suresop/api/orders/u_${userId}/v_${vendorId}/p_${productId}/sa_${selectedAddress}`,
            {
              color: color,
              size: "L",
              configurations: [
                {
                  type: "Storage",
                  name: "128GB",
                },
                {
                  type: "RAM",
                  name: "6GB",
                },
              ],
              coupon_code: "DISCOUNT10",
              final_price: finalPrice,
              payment_type: selectedPayment,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Correctly formatted token
              },
            }
          ),
          axios.delete(
            `https://uvfolderking.com/suresop/api/order-details/${deleteId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Correctly formatted token
              },
            }
          ),
        ]);
        console.log("promises all api ", response);
      }
    } catch (error) {
      console.log("the error in buying ", error);
    }
  };

  const handleaddAddress = () => {
    navigate("/account/newaddress-form");
  };

  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
      {/* Left Section - Address, Cart Items, Payment Options */}
      <div className="md:col-span-2 bg-white p-5 rounded-lg shadow-lg">
        {/* Login Details */}
        <div className="mb-6 shadow-sm rounded-lg overflow-hidden">
          <h2 className="text-2xl text-white bg-blue-500 font-bold mb-2 py-3 px-4">
            Login
          </h2>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-between items-center bg-gray-100 p-5">
            {/* User Name */}
            <div className="text-lg text-gray-700 font-medium">
              <p>
                {userProfile.first_name} {userProfile.last_name}
              </p>
            </div>
            {/* Mobile Number */}
            <div className="text-lg text-gray-700 font-medium">
              <p>{userProfile.mobile_number}</p>
            </div>
          </div>
        </div>

        {/* Address Selection */}
        <div className={`mb-6 border border-blue-500`}>
          <h2 className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500">
            Choose Delivery Address
            <span className="ml-2 text-gray-500"></span>
          </h2>

          <div className="space-y-4 pl-8 pr-5 ">
            {addresses.map((address) => (
              <div
                key={address.id}
                className="flex items-center space-x-3  border-b-2 pb-2"
              >
                <input
                  type="radio"
                  id={`address-${address.id}`}
                  name="address"
                  value={address.id}
                  onChange={handleAddressSelect}
                />
                <label
                  htmlFor={`address-${address.id}`}
                  className="ml-2  mr-5 w-full"
                >
                  <div className="flex justify-between mb-3">
                    <div className="font-semibold text-lg text-gray-700">
                      {address.full_name}
                    </div>
                    <div className="text-sm text-gray-600">
                      {address.mobile_number}
                    </div>
                  </div>
                  <div className="text-sm text-gray-600 mb-2">
                    {address.full_address} - {address.near_by_address},{" "}
                    {address.city}, {address.state}
                  </div>
                  <div className="text-sm text-gray-600">
                    Pin Code: {address.pin_code}
                  </div>
                </label>
              </div>
            ))}
            <div className="pb-2 " onClick={() => handleaddAddress()}>
              <div className="bg-blue-500 flex justify-center items-center text-white lg:pt-3 lg:pb-3 sm:pb-2 sm:pt-2">
                Add Address
              </div>
            </div>
          </div>
        </div>

        {/* Cart Items */}
        {isProduct ? (
          <div className={`mb-6 border border-blue-500 `}>
            <h2 className="text-xl font-bold mb-2 cursor-pointer  pt-3 pb-3 pl-3 text-gray-500">
              Your Item
            </h2>

            <ul className="space-y-4 pl-5 pr-5 pb-2">
              <li className="flex justify-between items-center border p-2">
                <div className="flex">
                  <div>
                    <img
                      className="w-20 h-20 object-cover"
                      src={`https://uvfolderking.com/suresop/storage/app/public/${Imagefeature}`}
                    />
                  </div>
                  <div className="ml-4">
                    <p className="font-semibold text-lg">{product.product.name}</p>
                    <p className="text-gray-600">Color: {product.color}</p>
                    <p className="text-gray-600">
                      Configuration: {product.configuration}
                    </p>
                  </div>
                </div>
                <div></div>
                <div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Price:</p>
                    <p className="text-green-600 font-semibold">
                      {product.sale_price}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-gray-700 mr-2">Quantity:</p>
                    <p className="text-green-600 font-semibold">
                      {product.quantity}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* Payment Options */}
        <div className={`mb-6 border border-blue-500 `}>
          <h2 className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500">
            Payment Options
            <span className="ml-2 text-gray-500"></span>
          </h2>

          <div className="space-y-4 pl-8 pb-3">
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id="UPI"
                name="payment"
                value="UPI"
                onChange={() => handlePaymentSelect("UPI")}
              />
              <label htmlFor="UPI" className="ml-2">
                Online Mode
              </label>
            </div>
            <div className="flex items-center space-x-3">
              <input
                type="radio"
                id="Cash On Delivery"
                name="payment"
                value="Cash On Delivery"
                onChange={() => handlePaymentSelect("Cash On Delivery")}
              />
              <label htmlFor="Cash On Delivery" className="ml-2">
                Cash on Delivery (COD)
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Right Section - Total Summary */}
      <div className="bg-white sticky top-20 p-6 rounded-lg shadow-lg sticky top-0">
        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>Total Items</p>
            {/* <p>{totalItems}</p> */}
            <p>1</p>
          </div>
          <div className="flex justify-between">
            <p>Total Price</p>

            {/* <p className="font-semibold text-blue-500">₹{product.sale_price}</p> */}
          </div>
        </div>
        <hr className="my-4" />
        <button
          className="w-full py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300"
          onClick={() =>
            handlePlaceOrder(
              product.id,
              product.product.vendor_id,
              product.product_id
            )
          }
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
        {orderSuccess && (
          <div className="mt-4 text-green-500 font-bold">
            Your order has been placed successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyNowOrderSummary;
