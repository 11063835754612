
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from "./app/pages/Home";
import Navbar from"./app/components/Navbar";
import NavUpper from"./app/components/NavUpper";
// import NavbarTwo from"./app/components/NavbarTwo";
import Footer from "./app/components/Footer";
import About from "./app/components/About";
import ContactUs from "./app/components/ContactUs";
import Cart from "./app/components/Cart";
import ProductDetail from './app/components/ProductDetail';
import OurStores from './app/components/OurStores';
import StoreDetails from './app/components/StoreDetails'
import Login from './app/components/Login';
import SignUp from './app/components/SignUp';
import ProductList from './app/components/ProductList';
import WishList from './app/components/WishList';
// import AddressForm from './app/components/AddressForm';
import CategoriesProduct from './app/components/CategoriesProduct';
import UserAccount from './app/pages/UserAccount/UserAccount';
import ProfileInformation from './app/pages/UserAccount/ProfileInformation';
import UserAddresses from './app/pages/UserAccount/UserAddresses';
import UserAddressForm from './app/pages/UserAccount/UserAddressForm';
import UserEditAddress from './app/pages/UserAccount/UserEditAddress';
import MyOrders from './app/pages/UserAccount/MyOrders';
import OrderDetail from './app/pages/UserAccount/OrderDetail';
import ProductDetails from './app/components/ProductDetails';
import NavBarSearchMobile from './app/components/NavBarSearchMobile';
import AboutUs from './app/components/AboutUs';
import PrivacyPolicy from './app/components/PrivacyAndPolicy';
import ShippingReturns from './app/components/ShippingAndReturn';
import TermsAndConitions from './app/components/TermsAndConditions';
import Disclaimer from './app/components/Discalimer';
import RefundAndCancellation from './app/components/RefundAndCancellation';
import OrderSummary from './app/components/OrderSummary';
import SubCategoryListing from './app/components/SubCategoryListing';
import SubCategoryListingProduct from './app/components/SubCategoryListingProduct';
import BuyNowOrderSummary from './app/components/BuyNowOrderSummary';

// import NavbarFlipkart from './app/components/NavbarFlipkart';




export default function App() {
  return (
    <Router>
       {/* <NavbarFlipkart/> */}
    <NavUpper/>
    <Navbar/>
    <NavBarSearchMobile/>

    {/* <NavbarTwo/> */}
      <Routes>
        <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
      
       <Route path="/login-user" element={<Login/>} />
       <Route path="/signup-user" element={<SignUp/>} />
       <Route path="/account" element={<UserAccount/>} />
       <Route path="/account/account_details" element={<ProfileInformation/>} />
       <Route path="/account/addresses" element={<UserAddresses/>} />
       <Route path="/account/:id" element={<UserEditAddress/>} /> 
        {/* <Route path="/account/editAddress_:id" element={<UserEditAddresses/>} />  */}
        <Route path="/ourstores" element={<OurStores/>} />
        <Route path="/ourstores/:id" element={<StoreDetails/>} />
        <Route path="/cart" element={<Cart/>} />
        <Route path="/category/:id" element={<SubCategoryListing/>} />
      

        
        <Route path="/account/newaddress-form" element={<UserAddressForm/>}/>  
        <Route path="/account/wishlist-products" element={<WishList/>}/>
        <Route path="/products" element={<ProductList/>} />
        <Route path="/products/:id" element={<ProductDetail/>} />  
        <Route path="/account/myorders" element={<MyOrders/>} />  
        <Route path="/orders/:id" element={<OrderDetail/>} /> 
        <Route path="/subcategory/products/:id" element={<SubCategoryListingProduct/>} /> 

        {/* /buySummary/${product.id} */}
      
        <Route path="/product/details" element={<ProductDetails/>} />

        <Route path="/about_us" element={<AboutUs/>} />
        <Route path="/contact_us" element={<ContactUs/>} />
        <Route path="/privacy_policy" element={<PrivacyPolicy/>} />
        <Route path="/shipping_returns" element={<ShippingReturns/>} />
        <Route path="/terms_conditions" element={<TermsAndConitions/>} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        <Route path="/refund_cancellation" element={<RefundAndCancellation/>} />
        <Route path="/order_summary" element={<OrderSummary/>} />
        <Route path="/buySummary/:id" element={<BuyNowOrderSummary/>} /> 

       

      </Routes>
      <Footer/>
  
   </Router> 


  
  );
}
