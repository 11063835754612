import React, { useState ,useEffect} from 'react';

const RefundAndCancellation = () => {

  const [orderId, setOrderId] = useState('');
  const [reason, setReason] = useState('');
  const [isRefundRequested, setIsRefundRequested] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleRefundRequest = () => {
    if (!orderId || !reason) {
      setMessage('Please fill in all fields.');
      return;
    }
    setLoading(true);
    // Simulate an API call to request a refund
    setTimeout(() => {
      setLoading(false);
      setIsRefundRequested(true);
      setMessage('Refund request submitted successfully!');
    }, 2000);
  };

  const handleCancelOrder = () => {
    if (!orderId || !reason) {
      setMessage('Please fill in all fields.');
      return;
    }
    setLoading(true);
    // Simulate an API call to cancel the order
    setTimeout(() => {
      setLoading(false);
      setIsCancelled(true);
      setMessage('Order cancellation successful!');
    }, 2000);
  };
  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="refund-cancellation-container max-w-lg mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">Refund & Cancellation</h2>
      <div className="mb-4">
        <label className="block text-gray-700" htmlFor="orderId">
          Order ID
        </label>
        <input
          type="text"
          id="orderId"
          value={orderId}
          onChange={handleOrderIdChange}
          className="w-full p-2 border rounded-md mt-2"
          placeholder="Enter your order ID"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700" htmlFor="reason">
          Reason
        </label>
        <textarea
          id="reason"
          value={reason}
          onChange={handleReasonChange}
          className="w-full p-2 border rounded-md mt-2"
          placeholder="Enter your reason for refund or cancellation"
          rows="4"
        ></textarea>
      </div>

      {message && <p className="text-center text-gray-700 mb-4">{message}</p>}

      <div className="flex justify-between space-x-2">
        <button
          onClick={handleRefundRequest}
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-all"
          disabled={loading || isRefundRequested || isCancelled}
        >
          {loading ? 'Processing...' : 'Request Refund'}
        </button>
        <button
          onClick={handleCancelOrder}
          className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition-all"
          disabled={loading || isRefundRequested || isCancelled}
        >
          {loading ? 'Processing...' : 'Cancel Order'}
        </button>
      </div>

      {(isRefundRequested || isCancelled) && (
        <div className="mt-4 text-center">
          <p className="text-green-500">
            {isRefundRequested
              ? 'Your refund has been successfully requested!'
              : 'Your order has been successfully cancelled!'}
          </p>
        </div>
      )}
    </div>
  );
};

export default RefundAndCancellation;
