import React,{useEffect} from "react";







const Disclaimer = () => {
    useEffect(() => {
      
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-semibold text-center mb-6">Disclaimer</h1>

      <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl mx-auto">
        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Introduction</h2>
          <p className="text-gray-700 mt-2">
            Welcome to our online store. By using our website, purchasing
            products, and engaging with our services, you agree to the following
            terms and conditions. Please read this disclaimer carefully. If you
            disagree with any part of the disclaimer, please do not use our
            website.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Product Information
          </h2>
          <p className="text-gray-700 mt-2">
            We make every effort to provide accurate and up-to-date information
            about the products listed on our website. However, we do not
            guarantee the accuracy, reliability, or completeness of product
            descriptions, pricing, or availability. All product prices and
            availability are subject to change without notice.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Pricing and Payment
          </h2>
          <p className="text-gray-700 mt-2">
            While we strive to provide accurate pricing information, errors may
            occur. In the event that an incorrect price is listed, we reserve
            the right to cancel the order and provide a refund or offer the
            product at the correct price. All payments made on our website are
            processed through secure third-party payment providers.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Third-Party Links and Affiliates
          </h2>
          <p className="text-gray-700 mt-2">
            Our website may contain links to third-party websites and affiliate
            links. These links are provided for your convenience and may lead to
            products or services that we do not control. We are not responsible
            for the content, policies, or actions of these third-party websites
            and do not endorse them. Please read the terms and privacy policies
            of third-party websites before engaging with them.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Returns and Refunds
          </h2>
          <p className="text-gray-700 mt-2">
            Our return and refund policy is outlined separately. We encourage
            you to review the terms of our return and refund policy before
            making a purchase. We are not responsible for any damages or issues
            caused by products after they have been used, worn, or altered in
            any way.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Intellectual Property
          </h2>
          <p className="text-gray-700 mt-2">
            All content, logos, trademarks, and other intellectual property on
            our website are the property of their respective owners. No content
            from this website may be reproduced, distributed, or otherwise used
            without proper permission from the relevant copyright holders.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Limitation of Liability
          </h2>
          <p className="text-gray-700 mt-2">
            We are not responsible for any direct, indirect, incidental,
            special, or consequential damages, including but not limited to loss
            of profits, loss of data, or business interruption, arising from
            your use of the website, even if we have been advised of the
            possibility of such damages.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Changes to Disclaimer
          </h2>
          <p className="text-gray-700 mt-2">
            We reserve the right to update, modify, or change this disclaimer at
            any time. Any updates will be posted on this page with a revised
            date. We encourage you to review this disclaimer periodically.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Contact Us</h2>
          <p className="text-gray-700 mt-2">
            If you have any questions regarding this disclaimer or our services,
            please contact us at{" "}
            <a
              href="mailto:support@example.com"
              className="text-blue-500 hover:text-blue-700"
            >
              support@example.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default Disclaimer;
