import axios from "axios";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, Link } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/categories/getallcategory`
      );
      setCategories(response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handlecategoryProductList = (categoryId) => {
    navigate(`/category_${categoryId}`);
  };
  return (
    <div className="bg-blue-50 xs:p-2 sm:p-4 md:p-6 lg:pb-3 lg:pt-2   relative">
      <Slider {...settings}>
        {categories.map((category) => (
          <div
            key={category.id}
            onClick={() => handlecategoryProductList(category.id)}
            className="flex flex-col items-center space-y-2 p-2"
          >
            <Link to={`/category_${category.id}`}>
              <div className="flex justify-center">
                <div className="rounded-full overflow-hidden border-2 border-blue-400 w-14 h-14 xs:w-12 xs:h-12 sm:w-16 sm:h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 relative">
                  <img
                    src={`https://uvfolderking.com/admin/storage/app/public/${category.image}`}
                    alt={category.name}
                     // Ensure the image covers the container
                    className=" object-cover w-full h-full rounded-full"
                  />
                </div>
              </div>
              <div className="text-center text-xs xs:text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                <h6>{category.name}</h6>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <button
    className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-gray-200 text-blue-600 rounded-full hover:bg-blue-600 hover:text-white transition-colors duration-300 z-10"
    onClick={onClick}
  >
    <GoChevronRight className="w-5 h-5" />
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    className="absolute top-1/2 left-2 transform -translate-y-1/2 p-2 bg-gray-200 text-blue-600 rounded-full hover:bg-blue-600 hover:text-white transition-colors duration-300 z-10"
    onClick={onClick}
  >
    <GoChevronLeft className="w-5 h-5" />
  </button>
);

export default Categories;
