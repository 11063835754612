import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import image from "../images/loginImage.png";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    otp: "",
  });
  console.log(signupData);

  const [otpSent, setOtpSent] = useState(false);

  const resendOTP = async (mobile_number) => {
    try {
      console.log("Sending Mobile No:", mobile_number);

      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/user/resend-otp`,
        {
          mobile_number: signupData.mobile_number,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("OTP Resent Successful:", response.data);
      // Assuming the API returns a success message or status indicating OTP should be sent
      setOtpSent(true);
      alert("OTP has been resent to your mobile number.");
    } catch (error) {
      console.error(
        "Error in resending OTP:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleResendOTP = () => {
    console.log(signupData);
    const { mobile_number } = signupData;
    if (mobile_number) {
      resendOTP(mobile_number);
    } else {
      alert("Mobile No Not Found");
    }
  };

  const signupUser = async (signupData) => {
    try {
      console.log("Sending signup data:", signupData);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/user/signup`,
        signupData
      );
      console.log("Signup successful:", response);
      setOtpSent(true);
      alert("OTP has been sent to your mobile number.");
    } catch (error) {
      console.error("Error during signup:", error);
      alert(
        "Error during signup. Try different email or mobile number.",
        error.response ? error.response.data : error.message
      );
    }
  };

  const verifyOtp = async (otp) => {
    try {
      const { otp, mobile_number } = signupData;
      console.log("Verifying OTP:", otp);
      const response = await axios.post(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/user/verify-otp`,
        { otp, mobile_number }
      );
      console.log("OTP verification successful:", response);
      alert("OTP verified successfully!");
      navigate("/login-user");
    } catch (error) {
      console.error(
        "Error during OTP verification:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleSignup = () => {
    const { first_name, last_name, email, mobile_number } = signupData;
    if (first_name && last_name && email && mobile_number) {
      signupUser(signupData);
    } else {
      alert("Please fill all fields");
    }
  };

  const handleOtpVerification = () => {
    if (signupData.otp) {
      verifyOtp(signupData.otp);
    } else {
      alert("Please enter the OTP sent to your mobile number");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
    <div className="flex flex-col sm:flex-row w-full max-w-3xl bg-white gap-5 p-6 sm:pt-5 shadow-lg rounded-lg">
      {/* Image Div */}
      <div className=" ">
        <img
          src={image}
          alt="Signup Illustration"
          className="w-full h-full object-cover"
        />
      </div>
  
      {/* Signup Form Div */}
      <div className="w-full md:w-1/2 flex flex-col justify-center">
        <h2 className="text-xl md:text-xl sm:text-sm font-semibold text-center mb-4">Sign Up</h2>
  
        {/* First Name Input */}
        <div className="mb-4">
          <label htmlFor="first_name" className="block mb-2 text-gray-600">
            First Name
          </label>
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={signupData.first_name}
            onChange={handleChange}
            placeholder="Enter your first name"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
  
        {/* Last Name Input */}
        <div className="mb-4">
          <label htmlFor="last_name" className="block mb-2 text-gray-600">
            Last Name
          </label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={signupData.last_name}
            onChange={handleChange}
            placeholder="Enter your last name"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
  
        {/* Email Input */}
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-gray-600">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={signupData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
  
        {/* Mobile Number Input */}
        <div className="mb-4">
          <label htmlFor="mobile_number" className="block mb-2 text-gray-600">
            Mobile Number
          </label>
          <input
            type="text"
            id="mobile_number"
            name="mobile_number"
            value={signupData.mobile_number}
            onChange={handleChange}
            placeholder="Enter your mobile number"
            className="w-full p-2 border border-gray-300 rounded-lg"
          />
        </div>
  
        {/* Signup Button */}
        <button
          onClick={handleSignup}
          className="w-full bg-green-500 text-white py-2 rounded-lg hover:bg-green-600 transition"
        >
          Sign Up
        </button>
  
        {/* OTP Input and Verification */}
        {otpSent && (
          <>
            <div className="mb-4 mt-4">
              <label
                htmlFor="otp"
                className="block mb-2 text-gray-600 flex justify-between items-end"
              >
                Enter OTP
                <span onClick={handleResendOTP} className="text-xs">
                  Resend OTP
                </span>
              </label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={signupData.otp}
                onChange={handleChange}
                placeholder="Enter OTP"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
  
            <button
              onClick={handleOtpVerification}
              className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Verify OTP
            </button>
          </>
        )}
  
        {/* Login Link */}
        <div className="mt-4 text-center">
          <p className="text-gray-600">
            Already have an account?{" "}
            <Link to="/login-user">
              <span className="text-blue-500 hover:underline">Login</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default SignUp;
