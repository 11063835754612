import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "../../../redux/action";

const MyOrders = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [order, setOrder] = useState([]);
  const userId = localStorage.getItem("userId");

  // Redux Orders
  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  console.log("the redux orders", orders);


  useEffect(() => {
  // Dispatch getOrders once when the component mounts
    dispatch(getOrders());
  }, [dispatch]);

  // const fetchOrder = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://uvfolderking.com/suresop/api/orders/user_id_${userId}`
  //     );
  //     console.log("the orders are", response.data.orders);
  //     setOrder(response.data.orders);
  //   } catch (error) {
  //     console.error("Error in fetching Order", error);
  //   }
  // };





  // Filtering based on search query, status, and year
  const filteredOrders = orders.filter((order) => {
    const matchesSearch = order.product.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus = filterStatus
      ? order.status.toLowerCase().includes(filterStatus)
      : true;
    const matchesYear = filterYear
      ? order.orderYear.toString() === filterYear
      : true;
    return matchesSearch && matchesStatus && matchesYear;
  });

  const handleOrdersDetails = (id) => {
    navigate(`/orders/${id}`);
  };

  const handleFilter = () => {
    window.location.reload();
  };

  return (
    <div className="flex lg:ml-10 lg:mr-10 sm:ml-3">
      {/* Sidebar Filters */}
      <div className="sticky top-10 border pl-5 h-[350px] md:h-[400px] w-[200px] shadow-lg bg-white">
        <div className="flex items-center justify-between gap-3">
          <h2 className="text-[25px] font-semibold mt-3">Filters</h2>
          <h3
            onClick={handleFilter}
            className="text-blue-500 mr-1 hover:text-blue-700 cursor-pointer mt-3"
          >
            Clear All
          </h3>
        </div>

        {/* Order Status Filter */}
        <h3 className="text-[18px] font-semibold mt-5">Order Status</h3>
        {["pending", "shipped", "delivered", "refund", "out for delivery", "order confirm"].map(status => (
          <div key={status}>
            <label>
              <input
                type="radio"
                name="status"
                value={status}
                onChange={(e) => setFilterStatus(e.target.value)}
              />
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </label>
          </div>
        ))}

        {/* Order Year Filter */}
        <h3 className="text-[18px] font-semibold mt-5">Order Time</h3>
        {["2024", "2023", "2022"].map(year => (
          <div key={year}>
            <label>
              <input
                type="radio"
                name="year"
                value={year}
                onChange={(e) => setFilterYear(e.target.value)}
              />
              {year}
            </label>
          </div>
        ))}
      </div>

      {/* Main Content */}
      <div className="flex-grow p-5 bg-gray-100 shadow-lg rounded-md ml-5">
        {/* Search Input */}
        <div>
          <input
            type="text"
            placeholder="Search orders..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 border border-blue-300 rounded mb-4"
          />
        </div>

        {/* Display Orders */}
        {filteredOrders.length > 0 ? (
          filteredOrders.map((order) => (
            <div
              key={order.id}
              onClick={() => handleOrdersDetails(order.id)}
              className="flex justify-between bg-white p-4 mb-4 rounded shadow"
            >
              <div className="flex">
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${order.product.featured_image}`}
                  alt={order.product.name}
                  className="w-20 h-20 object-cover mr-4"
                />
                <div>
                  <p className="font-semibold">{order.product.name}</p>
                  <p>Color: {order.color}</p>
                </div>
              </div>
              <p className="text-lg font-bold">${order.final_price}</p>
              <div className="flex flex-col justify-center items-end">
                <p className="text-sm font-semibold text-gray-500">
                  {order.status}
                </p>
                <p className="text-sm text-gray-400">
                  {order.statusDescription}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No orders found matching the criteria.</p>
        )}
      </div>
    </div>
  );
};

export default MyOrders;
