import axios from "axios";
import React, { useState, useEffect } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { addproduct } from "../../redux/action";

const WishList = () => {
  // Local Storage Variables
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");

  // State Management
  const [wishlistItems, setWishlistItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Redux Dispatch and Selector
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  console.log("wishlist items ",wishlistItems);
  // Fetch Wishlist Items
  const fetchWishList = async () => {
    if (!token) {
      alert("You need to log in to view the wishlist.");
      return;
    }
    try {
      const response = await axios.get(
        `https://uvfolderking.com/suresop/api/wishlist/user_id_${userId}`
      );
      
      setWishlistItems(response.data.wishlistItems);
    } catch (error) {
      console.error("Error fetching wishlist items", error);
    }
  };

  // Remove Item from Wishlist
  const handleRemoveItem = async (itemId, vendor_id, product_id) => {
    try {
      await axios.delete(
        `https://uvfolderking.com/suresop/api/wishlist/user_id_${userId}/vendor_id_${vendor_id}/product_id_${product_id}/wishlist_id_${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWishlistItems((prevItems) =>
        prevItems.filter((item) => item.id !== itemId)
      );
    } catch (error) {
      console.error("Error removing item from wishlist", error);
    }
  };

  // Clear Wishlist
  const handleClearWishlist = () => {
    setWishlistItems([]);
  };

  // Open Modal to Display Selected Item
  const handleItemSelect = (item) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  // Fetch Products and Wishlist on Mount
  useEffect(() => {
    dispatch(addproduct());
    fetchWishList();
  }, [dispatch]);

  return (
    <div className="max-w-4xl mx-auto p-2">
      <h2 className="text-2xl font-bold mb-4 flex items-center">
        <AiOutlineHeart className="mr-3 text-2xl" />
        My Wishlist
      </h2>

      {wishlistItems.length > 0 ? (
        <ul className="space-y-6">
          {wishlistItems.map((item) => {
            const product = products.find((p) => p.id === item.product_id);

            return (
              <li
                key={item.id}
                className="flex justify-between items-center p-6 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div
                  className="flex items-center"
                  onClick={() => handleItemSelect(item)}
                >
                  <div className="relative w-20 h-20 mr-6">
                    <img
                      src={
                        product
                          ? `https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`
                          : "/placeholder.jpg"
                      }
                      alt={product ? product.name : "Product image"}
                      className="rounded-lg object-cover w-full h-full border"
                    />
                  </div>
                  <div>
                    <p className="text-lg font-bold">
                      {product ? product.name : "Product not available"}
                    </p>
                    <p>{item.color}</p>
                    <p>₹{item.sale_price}</p>
                  </div>
                </div>

                <div className="flex items-center text-green-500 space-x-3">
                  {item.status === "In Stock" ? (
                    <div className="flex items-center space-x-2">
                      <BiCheckCircle className="text-xl" />
                      <p className="text-sm font-medium">In Stock</p>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <BiXCircle className="text-xl" />
                      <p className="text-sm font-medium">Out of Stock</p>
                    </div>
                  )}
                </div>

                <button
                  className="flex items-center text-red-600 space-x-2 hover: transition duration-200"
                  onClick={() =>
                    handleRemoveItem(item.id, item.vendor_id, item.product_id)
                  }
                >
                  <RiDeleteBin6Line className="text-xl" />
                  <span className="text-lg hidden sm:inline">Remove</span>
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-center text-lg">Your wishlist is empty.</p>
      )}

      {wishlistItems.length > 0 && (
        <div className="flex justify-end mt-8">
          <button
            className="py-2 px-6 rounded-lg border border-gray-300 hover:bg-gray-200 transition duration-200"
            onClick={handleClearWishlist}
          >
            Clear Wishlist
          </button>
        </div>
      )}

      {modalOpen && selectedItem && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-80">
            <h3 className="text-xl font-semibold">
              {selectedItem.name || "Product details"}
            </h3>
            <img
              src={selectedItem.image || "/placeholder.jpg"}
              alt={selectedItem.name || "Product image"}
              className="w-full h-48 object-cover rounded-lg mt-4"
            />
            <p className="mt-2">Price: ₹{selectedItem.sale_price}</p>
            <p className="mt-2">Status: {selectedItem.status}</p>
            <button
              className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Add to Cart
            </button>
            <button
              className="mt-2 py-2 px-4 border border-red-500 text-red-500 rounded hover:bg-red-100 transition"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WishList;
