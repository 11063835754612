import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { addproduct } from "../../redux/action";
import { Link, Navigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTag, FaShoppingCart } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const ProductCard = () => {
  // const [products, setProduct] = useState([]);
  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  console.log("the redux product is ", products);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);

  function filterArrayProduct(arr) {
    return arr.map((product) => {
      let prices = product.prices;
      let lowprice;
      if (prices.length === 1) {
        lowprice = prices[0];
      } else {
        lowprice = prices.reduce((prev, curr) =>
          prev.sale_price > curr.sale_price ? prev : curr
        );
      } // Return a new object for each product with the added lowprice field
      return {
        ...product,
        lowprice,
      };
    });
  }

  const productPriceArray = filterArrayProduct(products);

  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-gray-600" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-gray-600" />;
      } else {
        return <FaRegStar key={index} className="text-gray-600" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  const handleProductClick = (productId) => {
    Navigate(`/products/${productId}`);
  };

  return (
    <div className="mx-auto p-2 sm:p-4 md:p-6 lg:p-10 xl:p-10 xl:pt-2">
               
      <div className="flex text-center justify-between items-center mb-4">
                        <p className="font-bold text-xl">Featured Products</p> 
             
      </div>
               
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                   
        {productPriceArray.map((product, index) => (
          <div
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-110"
          >
            <Link to={`/products/${product.id}`}>
              <div className="relative">
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  // Set height for the image
                  className="object-cover w-full h-48"
                />
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  width={400} // Set width for the image
                  height={300} // Set height for the image
                  className="absolute inset-0 object-cover w-full h-48 opacity-0 group-hover:opacity-100 transform transition-transform duration-1000 ease-in-out hover:scale-110"
                />
                <div className="absolute top-1 left-1 bg-blue-500 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <FaTag className="mr-1" /> Discount
                </div>
                <div className="absolute top-1 right-1 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <AiOutlineHeart className="w-5 h-5 inline text-red-500" />
                </div>
              </div>
              <div className="p-4">
                <p className="text-start text-lg font-semibold mb-2">
                  {product.name}
                </p>
                <p className="text-sm text-gray-600 mb-2">
                  {product.description.slice(0, 50)}
                </p>
                <div className="text-sm text-gray-600 mb-2">
                  {renderStars(product.rating)}
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2 mb-2">
                    <span className="text-lg font-bold text-green-500">
                      ₹{product.lowprice.sale_price}
                    </span>
                    <span className="text-sm text-gray-500 line-through">
                      ₹{product.lowprice.old_price}
                    </span>
                  </div>
                  <button className="bg-blue-500 text-white py-2 rounded-lg text-center w-full flex items-center justify-center hover:bg-blue-300">
                    <FaShoppingCart className="mr-2" /> Add to Cart
                  </button>
                </div>
              </div>
            </Link>
          </div>
        ))}
                 
      </div>
         
    </div>
  );
};

export default ProductCard;
