import axios from "axios";
import React, { useState, useEffect } from "react";
import { addproduct, getCartItems } from "../../redux/action";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiSave } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
// import { addproduct } from "../../redux/action";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  console.log("cartItems is ",cartItems);

  const products = useSelector((state) => state.products);
  console.log("the product is in cart is ", products);
  const cart = useSelector((state) => state.cart);
  console.log("the cartpage redux cart is ",cart);
  const dispatch = useDispatch();

  const fetchCartItems = async () => {
    setLoading(true);
    setError(null);

    if (!token) {
      setError("You need to log in to view the cart.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://uvfolderking.com/suresop/api/cart/user_id_${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token to authenticate the request
          },
        }
      );
      setCartItems(response.data.cartItems);
      console.log("cart",response.data.cartItems);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("Failed to load cart items.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getCartItems());
  // }, [dispatch]);

  const countCartQuantity = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };
  console.log("the quantity is ", countCartQuantity());

  useEffect(() => {
    // Calculate the total price based on cart items
    const total = cartItems.reduce(
      (acc, item) => acc + item.sale_price * item.quantity,
      0
    );
    setTotalPrice(total);
  }, [cartItems]);

  const handleQuantityChange = async (
    id,
    product_id,
    vendor_id,
    newQuantity
  ) => {
    if (newQuantity < 1) return; // Prevent decreasing quantity below 1

    try {
      // Update the quantity via API
      await axios.put(
        `https://uvfolderking.com/suresop/api/cart/user_id_${userId}/vendor_id_${vendor_id}/product_id_${product_id}/cart_id_${id}`,
        {
          quantity: newQuantity,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the cart items state with the new quantity
      const updatedCart = cartItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      );
      setCartItems(updatedCart);
    } catch (error) {
      console.error("Error updating quantity:", error);
      setError("Failed to update the quantity.");
    }
  };

  const handleRemoveItem = async (id, product_id, vendor_id) => {
    try {
      const updatedCart = await axios.delete(
        `https://uvfolderking.com/suresop/api/cart/user_id_${userId}/vendor_id_${vendor_id}/product_id_${product_id}/cart_id_${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token to authenticate the request
          },
        }
      );
      setCartItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
    )
    } catch (error) {
      console.log("Error in delete Cart", error);
    }
  };

  // navigate to the product detail page with category
  const handleproductDetailpage = (product_id) => {
    navigate(`/products/${product_id}`);
  };

  return (
    <div className=" bg-green-50 container mx-auto p-6">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : cartItems.length === 0 ? (
        <p className="text-center text-gray-600">
          Your cart is empty.{" "}
          <Link to="/products" className="text-blue-500 underline">
            Continue Shopping
          </Link>
        </p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
          {/* Delivery Info */}
          <div className="col-span-1 lg:col-span-3 bg-white shadow-md rounded-lg p-5 flex justify-between items-center ">
            <div>
              <h2 className="text-lg font-semibold text-gray-800">
                Deliver to: Ankit Shakya, 144401
              </h2>
              <p className="text-sm text-gray-500">
                Prabhakar house, Handa molla, katharia chock (Sudhir Sweets)
              </p>
            </div>
            <Link
              to="/address-form"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Change
            </Link>
          </div>

          {/* Cart Items */}
          <div className="md:col-span-2 lg:col-span-2">
            {cartItems.map((item) => {
              const product = products.find((p) => p.id === item.product_id); // Move logic outside of JSX
              return (
                <div
                  key={item.id}
                  className="flex items-center justify-between bg-white lg:p-4 mb-4 rounded-lg shadow-md flex-col sm:flex-row gap-10"
                >
                  <div
                    onClick={() => handleproductDetailpage(item.product_id)}
                    className="relative "
                  >
                    <img
                      className=" border w-24 h-24 mr-6 object-cover"
                      src={`https://uvfolderking.com/suresop/storage/app/public/${item.image}`}
                      alt=""
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row sm:pl-6 w-full">
                    <div className="sm:w-2/3">
                      <h2 className="text-lg font-semibold text-gray-800">
                        {product.name}
                      </h2>
                      <p className="text-sm text-gray-500 mt-1">
                        Color: {item.color}
                      </p>
                      <p className="text-sm text-gray-500">
                        Seller: {product?.sellerName || "Unknown Seller"}
                      </p>
                      <div className="flex items-center space-x-4 mt-2">
                        <p className="text-gray-400 line-through">
                          ₹{item.old_price}
                        </p>
                        <p className="text-xl font-semibold text-gray-800">
                          ₹{item.sale_price}
                        </p>
                        <p className="text-green-600 text-sm">
                          {Math.round(
                            ((item.old_price - item.sale_price) /
                              item.old_price) *
                              100
                          )}
                          % Off
                        </p>
                        <p className="text-green-500 text-sm">Coupon applied</p>
                      </div>
                      <div className="flex items-center mt-4 space-x-4">
                        <button
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              item.product_id,
                              item.vendor_id,
                              item.quantity - 1
                            )
                          }
                          className="px-3 py-1 bg-gray-200 text-gray-800 border rounded hover:bg-gray-300 transition"
                          disabled={item.quantity <= 1}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          min="1"
                          value={item.quantity}
                          onChange={(e) =>
                            handleQuantityChange(
                              item.id,
                              item.product_id,
                              item.vendor_id,
                              Number(e.target.value)
                            )
                          }
                          className="w-16 text-center border rounded p-1"
                        />
                        <button
                          onClick={() =>
                            handleQuantityChange(
                              item.id,
                              item.product_id,
                              item.vendor_id,
                              item.quantity + 1
                            )
                          }
                          className="px-3 py-1 bg-gray-200 text-gray-800 border rounded hover:bg-gray-300 transition"
                        >
                          +
                        </button>
                        <p className="pl-6 text-lg font-semibold">
                          ₹{item.sale_price * item.quantity}
                        </p>
                      </div>
                      <div className="flex items-center mt-4 space-x-6">
                        <button
                          className="text-blue-500 flex items-center space-x-2 hover:text-blue-700 transition"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <BiSave className="text-xl" />
                          <span>Save for later</span>
                        </button>
                        <button
                          className="text-red-500 flex items-center space-x-2 hover:text-red-700 transition"
                          onClick={() =>
                            handleRemoveItem(
                              item.id,
                              item.product_id,
                              item.vendor_id
                            )
                          }
                        >
                          <RiDeleteBin6Line className="text-xl" />
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* Cart Summary */}
          <div className="sticky top-24 bg-white p-6 rounded-lg shadow-md h-[400px] sm:w-full md:w-2/3 lg:w-3/4">
            <h2 className="text-xl font-semibold mb-4">Price Details</h2>
            <div className="flex justify-between mb-2">
              <p>Subtotal</p>
              <p>₹{totalPrice}</p>
            </div>
            <div className="flex justify-between mb-2">
              <p>Discount</p>
              <p className="text-green-500">₹{totalPrice * 0.3}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p>Delivery Charges</p>
              <p className="text-green-500">Free</p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between text-lg font-semibold">
              <p>Total</p>
              <p>₹{totalPrice - totalPrice * 0.3}</p>
            </div>
            <Link to="/order_summary">
              <button className="w-full py-2 bg-orange-400 text-white rounded-lg mt-6 hover:bg-orange-500 transition">
                Place Order
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
