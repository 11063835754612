import React, { useState, useEffect } from "react";
import axios from "axios";
import UserAccount from "./UserAccount";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "./../../../redux/action";

const ProfileInformation = () => {
  const [user, setUser] = useState([]);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [width, setWidth] = useState(false);

  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(setUserProfile());
  }, [dispatch]);
  console.log("the redux profile is ", userProfile.UserProfile);

  return (
    <div className=" relative flex gap-4 ">
      <div>
        <UserAccount />
      </div>

      <div className="flex-grow w-full p-5 mr-10 bg-gray-100 shadow-lg rounded-md">
      
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-semibold">Personal Information</h2>
        </div>

        <form className="p-4 rounded">
          {/* Name Fields */}
          <div className="mb-6 flex flex-col md:flex-row md:gap-4">
            <input
              type="text"
              id="firstName"
              placeholder="First Name"
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <input
              type="text"
              id="lastName"
              placeholder="Last Name"
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Gender Selection */}
          <div className="mb-6">
            <label className="block text-gray-700 mb-2">Your Gender</label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="male"
                name="gender"
                value="male"
                className="mr-2"
                required
              />
              <label htmlFor="male" className="text-gray-700 mr-4">
                Male
              </label>

              <input
                type="radio"
                id="female"
                name="gender"
                value="female"
                className="mr-2"
                required
              />
              <label htmlFor="female" className="text-gray-700">
                Female
              </label>
            </div>
          </div>

          {/* Email Address Field */}
          <div className="mb-6">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              placeholder="Email Address"
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Mobile Number Field */}
          <div className="mb-6">
            <label htmlFor="mobile" className="block text-gray-700 mb-2">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              placeholder="Mobile Number"
              className="w-full max-w-xs p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          {/* Update Button */}
          <button
            type="submit"
            className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
          >
            Update Profile
          </button>

          {/* Account Deletion Warning */}
          <div className="mb-4 mt-5">
            <p className="text-gray-600">
              Deleting your account will remove all your data permanently.
            </p>
            <button
              type="button"
              className="mt-2 text-red-600 hover:underline"
              // onClick={handleDeleteAccount}
            >
              Delete Account
            </button>
          </div>

          {/* FAQs Section */}
          <h2 className="text-xl font-bold mt-10">FAQs</h2>
          <div className="mt-4">
            <div className="mb-4">
              <h3 className="font-semibold">
                What happens when I update my email address (or mobile number)?
              </h3>
              <p className="text-gray-600">
                Your login email id (or mobile number) changes, likewise. You'll
                receive all your account-related communication on your updated
                email address (or mobile number).
              </p>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold">
                When will my Flipkart account be updated with the new email
                address (or mobile number)?
              </h3>
              <p className="text-gray-600">
                It happens as soon as you confirm the verification code sent to
                your email (or mobile) and save the changes.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold">
                What happens to my existing Flipkart account when I update my
                email address (or mobile number)?
              </h3>
              <p className="text-gray-600">
                Updating your email address (or mobile number) doesn't
                invalidate your account. Your account remains fully functional.
                You'll continue seeing your order history, saved information,
                and personal details.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="font-semibold">
                Does my Seller account get affected when I update my email
                address?
              </h3>
              <p className="text-gray-600">
                Flipkart has a 'single sign-on' policy. Any changes will reflect
                in your Seller account also.
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileInformation;
