import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import {
  addproduct,
  getCartItems,
  setUserProfile,
  userAddresses,
} from "./../../redux/action";
import { useNavigate } from "react-router-dom";

const OrderSummary = () => {
  // Static cart items
  const cartItems = [
    { id: 1, name: "Product A", price: 1000, quantity: 2 },
    { id: 2, name: "Product B", price: 1500, quantity: 1 },
  ];

  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const navigate = useNavigate();

  const cart = useSelector((state) => state.cart);
  console.log("the redux cart is ", cart);
  const products = useSelector((state) => state.products);
  console.log("the redux products is ", products);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);

  const userProfile = useSelector((state) => state.userProfile);

  useEffect(() => {
    dispatch(setUserProfile());
  }, [dispatch]);

  console.log("The redux profile in account now is ", userProfile);

  const address = useSelector((state) => state.userAddresses);
  console.log("The redux address is: ", address);
  console.log("The redux cart is: ", cart);

  useEffect(() => {
    dispatch(userAddresses());
  }, [dispatch]);

  // Calculate total price and item count based on static cart items
  useEffect(() => {
    const total = cart.reduce(
      (acc, item) => acc + item.sale_price * item.quantity,
      0
    );
    const items = cart.reduce((acc, item) => acc + item.quantity, 0);
    setTotalPrice(total);
    setTotalItems(items);
  }, []);

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  const handleAddressSelect = (e) => {
    const addressId = e.target.value;
    setSelectedAddress(addressId);
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
  };

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  // Prepare the order data
  const formData = {
    username: userProfile.first_name,
    mobile: userProfile.mobile_number,
    totalItems: totalItems,
    totalPrice: totalPrice,
    selectedAddress: selectedAddress,
    selectedPayment: selectedPayment,
    cartItems: cartItems,
  };

  // Function to handle placing the order
  const handlePlaceOrder = async () => {
    if (!selectedAddress || !selectedPayment) {
      const response = await axios.post(
        `https://uvfolderking.com/suresop/api/orders/u_7/v_12/p_109/sa_21`,
        formData
      );
      return;
    }

    console.log("Order Data:", formData);
    setOrderSuccess(true);
  };
  const handleaddAddress = () => {
    navigate("/account/newaddress-form");
  };
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
      {/* Left Section - Address, Cart Items, Payment Options */}
      <div className="md:col-span-2 bg-white p-5 rounded-lg shadow-lg">
        {/* Login Details */}
        <div className="mb-6 shadow-sm rounded-lg overflow-hidden">
          <h2 className="text-2xl text-white bg-blue-500 font-bold mb-2 py-3 px-4">
            Login
          </h2>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-between items-center bg-gray-100 p-5">
            {/* User Name */}
            <div className="text-lg text-gray-700 font-medium">
              <p>
                {userProfile.first_name} {userProfile.last_name}
              </p>
            </div>
            {/* Mobile Number */}
            <div className="text-lg text-gray-700 font-medium">
              <p>{userProfile.mobile_number}</p>
            </div>
          </div>
        </div>

        {/* Address Selection */}
        <div
          className={`mb-6 border border-blue-500 ${
            activeSection === "address" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500"
            onClick={() => toggleSection("address")}
          >
            Choose Delivery Address
            <span className="ml-2 text-gray-500">
              {activeSection === "address" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "address" && (
            <div className="space-y-4 pl-8 pr-5 ">
              {address.addresses.map((address) => (
                <div
                  key={address.id}
                  className="flex items-center space-x-3  border-b-2 pb-2"
                >
                  <input
                    type="radio"
                    id={`address-${address.id}`}
                    name="address"
                    value={address.id}
                    onChange={handleAddressSelect}
                  />
                  <label
                    htmlFor={`address-${address.id}`}
                    className="ml-2  mr-5 w-full"
                  >
                    <div className="flex justify-between mb-3">
                      <div className="font-semibold text-lg text-gray-700">
                        {address.full_name}
                      </div>
                      <div className="text-sm text-gray-600">
                        {address.mobile_number}
                      </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-2">
                      {address.full_address} - {address.near_by_address},{" "}
                      {address.city}, {address.state}
                    </div>
                    <div className="text-sm text-gray-600">
                      Pin Code: {address.pin_code}
                    </div>
                  </label>
                </div>
              ))}
              <div className="pb-2 " onClick={() => handleaddAddress()}>
                <div className="bg-blue-500 flex justify-center items-center text-white lg:pt-3 lg:pb-3 sm:pb-2 sm:pt-2">
                  Add Address
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Cart Items */}
        <div
          className={`mb-6 border border-blue-500 ${
            activeSection === "cart" ? "bg-white" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer  pt-3 pb-3 pl-3 text-gray-500"
            onClick={() => toggleSection("cart")}
          >
            Your Cart Items
            <span className="ml-2 text-gray-500">
              {activeSection === "cart" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "cart" && (
            <ul className="space-y-4 pl-5 pr-5">
              {cart.map((item) => {
                const product = products.find((p) => p.id === item.product_id);
                return (
                  <li
                    key={item.id}
                    className="flex justify-between items-center py-2 px-3 border"
                  >
                    <div className="flex">
                      <div>
                        {" "}
                        <img
                          className="w-20 h-20"
                          src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col sm:pl-4 lg:pl-10">
                        <h3 className="font-semibold">{product.name}</h3>
                        <h3 className="font-semibold">{item.color}</h3>

                        <p>Qty: {item.quantity}</p>
                      </div>
                    </div>

                    <p className="font-semibold text-blue-500">
                      ₹{item.sale_price * item.quantity}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}
        </div>

        {/* Payment Options */}
        <div
          className={`mb-6 border border-blue-500 ${
            activeSection === "payment" ? "bg-gray-100" : ""
          }`}
        >
          <h2
            className="text-xl font-bold mb-2 cursor-pointer bg-white pt-3 pb-3 pl-3 text-gray-500"
            onClick={() => toggleSection("payment")}
          >
            Payment Options
            <span className="ml-2 text-gray-500">
              {activeSection === "payment" ? "-" : "+"}
            </span>
          </h2>
          {activeSection === "payment" && (
            <div className="space-y-4 pl-8 pb-3">
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="upi"
                  name="payment"
                  value="upi"
                  onChange={() => handlePaymentSelect("upi")}
                />
                <label htmlFor="upi" className="ml-2">
                  Online Mode
                </label>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="cod"
                  name="payment"
                  value="cod"
                  onChange={() => handlePaymentSelect("cod")}
                />
                <label htmlFor="cod" className="ml-2">
                  Cash on Delivery (COD)
                </label>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Right Section - Total Summary */}
      <div className="bg-white sticky top-20 p-6 rounded-lg shadow-lg sticky top-0">
        <h2 className="text-xl font-bold mb-4">Order Summary</h2>
        <div className="space-y-2">
          <div className="flex justify-between">
            <p>Total Items</p>
            <p>{totalItems}</p>
          </div>
          <div className="flex justify-between">
            <p>Total Price</p>
            <p className="font-semibold text-blue-500">₹{totalPrice}</p>
          </div>
        </div>
        <hr className="my-4" />
        <button
          className="w-full py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300"
          onClick={handlePlaceOrder}
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
        {orderSuccess && (
          <div className="mt-4 text-green-500 font-bold">
            Your order has been placed successfully!
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
