import axios from "axios";
import React,{useState,useEffect} from 'react';
import {Link} from "react-router-dom"
import { useNavigate } from "react-router-dom";

const OurStores = () => {
  const [stores,setStore]=useState([]);
  const navigate = useNavigate();
  

const fetchStore=async ()=>{
  try{
    const response= await axios.get(`${process.env.REACT_APP_PUBLIC_API_BASE_URL}/user/shops`);
    setStore(response.data.shops); 
    console.log(response.data.shops)
  }catch(error){
    console.error('Error in Fetching stores',error);
  }
}

useEffect(()=>{
  fetchStore();
},[])
  const handleStore=(id)=>{
    navigate(`/ourstores/${id}`);
  }

  return (
    <div className=" ml-10 mr-10  min-h-screen">
      <h1 className="text-4xl font-bold text-center mt-5 mb-8">Our Stores</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {stores.map((store,index) => (
        
          <div key={store.shop_id} onClick={()=>handleStore(store.shop_id)} className="bg-white shadow-md rounded-lg overflow-hidden">
            <Link to={`/ourstores/${store.shop_id}`}> {/* <img
              src={store.image.src}
              alt={store.shop_name}
              className="w-full h-48 object-cover"
            /> */}
            <div className="p-4">
              <h2 className="text-2xl font-bold mb-2">{store.shop_name}</h2>
              <p className="text-yellow-500 mb-2">Ratings: {store.average_rating} ★</p>
              <p className=""> Category: {store.shop_category}</p>
              <p className="mt-2 text-gray-500">{store.shop_address}</p>
            </div>
            </Link>
          </div>
       
        ))}
      </div>
    </div>
  );
};

export default OurStores;