import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import image from "../../images/th.jpeg";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../../redux/action';

const OrderDetails = () => {
  const { id } = useParams(); // Get the id from the URL
  const orderId = parseInt(id);
  const navigate=useNavigate();

  const orders = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  console.log("the redux orders detail", orders);
  useEffect(() => {
    // Dispatch getOrders once when the component mounts
    dispatch(getOrders());
  }, [dispatch]);

  const details = [
  {
      id: 1,
      productName: "SAMSUNG Galaxy S23 5G (Cream, 256 GB)",
      color: "Cream",
      price: 9999,
      status: "Refund in process",
      statusDescription: "As per your request, your item has been cancelled.",
      orderYear: 2024,
      image: image,
      address: {
        street: "123 Main Street",
        city: "Los Angeles",
        state: "California",
        postalCode: "90001",
        country: "USA",
      },
    },
  ];


  
  const product = orders.find((item) => item.id === orderId);
  console.log("found product in order detail",product);

  // If no product is found, show a message
  if (!product) {
    return <div className="p-6">Product not found</div>;
  }

  const handleProductDetail=(id)=>{
navigate(`/products/${id}`)
  }
  return (
    <div  className="lg:p-10 sm:p-5">
      <h1 className="text-2xl font-bold mb-6">Order Details</h1>



      <div className="mt-4 border p-4 mb-6 rounded-lg shadow-md">
          <h3 className="font-semibold">Delivery Address:</h3>
          <p className="font-semibold">Ankit Shakya</p>
          <p>{product.shipping_address_id}</p>
        
          <p className="font-semibold">Mobile No: 8630352371</p>
        </div>


      <div onClick={()=>handleProductDetail(product.product.id)} className="border p-4 mb-6 rounded-lg shadow-md">
       <div className="flex justify-between">
       <div className="flex gap-6">
          <img
            src={`https://uvfolderking.com/suresop/storage/app/public/${product.product.featured_image}`}
            alt={product.product.name}
            className="w-32 h-32 object-cover rounded-lg"
          />
          <div className="pl-5">
            <h2 className="font-bold text-xl">{product.product.name}</h2>
            <p>Color: {product.color}</p>
            <p>Price: ${product.final_price}</p>
          
            <p>Status Description: {product.statusDescription}</p>
          
          </div>
          </div>
          <div > 
          <p>Order Id:{product.order_id}</p>
           <p>Status:{product.status}</p>
          <p>Order Year: {product.created_at}</p></div>
       </div>
       
        
        </div>
       
      
    </div>
  );
};

export default OrderDetails;
