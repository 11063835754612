import React,{useEffect} from "react";

const ShippingReturns = () => {
    useEffect(() => {
        // Scroll to top when the component is mounted
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-8">Shipping & Returns</h1>

      <div className="space-y-8">
        {/* Shipping Policy */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Shipping Policy</h2>
          <p>
            We strive to deliver your order in the fastest and most efficient way possible. Shipping costs and times may vary depending on your location and the items ordered. Below is a general overview of our shipping policies:
          </p>

          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li><strong>Processing Time:</strong> Orders are processed within 1-2 business days.</li>
            <li><strong>Shipping Time:</strong> Standard shipping typically takes 3-7 business days, depending on your location.</li>
            <li><strong>Shipping Costs:</strong> We offer free shipping on orders over $50. For orders under $50, a flat rate shipping fee of $5 will be applied.</li>
            <li><strong>International Shipping:</strong> We currently only ship within [Your Country]. International shipping is not available at this time.</li>
            <li><strong>Tracking Information:</strong> Once your order has been shipped, you will receive a tracking number via email to monitor your shipment.</li>
          </ul>
        </section>

        {/* Returns & Refunds */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Returns & Refunds</h2>
          <p>
            If you are not satisfied with your purchase, we are here to help! Please read our returns and refunds policy below:
          </p>

          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li><strong>Return Window:</strong> You have 30 calendar days from the date you received your order to return it for a refund or exchange.</li>
            <li><strong>Eligible Items:</strong> To be eligible for a return, your item must be unused, in the same condition that you received it, and in its original packaging.</li>
            <li><strong>Non-Returnable Items:</strong> Some items cannot be returned, including gift cards, downloadable products, and personalized items.</li>
            <li><strong>Return Process:</strong> To initiate a return, please contact our customer service at <a href="mailto:returns@example.com" className="text-blue-600 hover:underline">returns@example.com</a> with your order number and details of the product you wish to return.</li>
            <li><strong>Return Shipping Costs:</strong> You will be responsible for covering the return shipping costs unless the item is defective or we made an error with your order.</li>
            <li><strong>Refunds:</strong> Once we receive your return, we will inspect the item and notify you about the status of your refund. If approved, your refund will be processed to your original payment method within 5-7 business days.</li>
          </ul>
        </section>

        {/* Exchanges */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Exchanges</h2>
          <p>
            If you need to exchange an item for a different size or color, we are happy to accommodate exchanges within 30 days of purchase. Please ensure that the item is unused and in its original condition. 
          </p>

          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li>To start an exchange, contact us at <a href="mailto:exchanges@example.com" className="text-blue-600 hover:underline">exchanges@example.com</a> with your order number and exchange request.</li>
            <li>Once the returned item is received and inspected, we will ship the replacement item to you.</li>
            <li>If the replacement item is out of stock, we will issue a refund instead.</li>
          </ul>
        </section>

        {/* Damaged or Defective Items */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Damaged or Defective Items</h2>
          <p>
            If you receive an item that is damaged or defective, please contact us immediately. We will arrange a replacement or provide a full refund, including return shipping costs. 
          </p>
          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li>Contact our support team within 7 days of receiving the item.</li>
            <li>Include photos of the damaged or defective product, along with your order number, in your email.</li>
            <li>We will provide you with a prepaid shipping label to return the item if necessary.</li>
          </ul>
        </section>

        {/* Contact Us */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>
            If you have any questions or concerns regarding our shipping and returns policy, feel free to contact our customer service team:
          </p>
          <ul className="list-disc pl-5 space-y-2 mt-4">
            <li>Email: <a href="mailto:support@example.com" className="text-blue-600 hover:underline">support@example.com</a></li>
            <li>Phone: +1 (555) 123-4567</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default ShippingReturns;
