import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SubCategoryListing = () => {
  const { id } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  console.log("category id ", id);
  const navigate = useNavigate();

  // Fetch subcategories by category ID
  const getSubCategoriesByCategoryId = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/getSubCatgoryBy/${id}`
      );

      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  console.log("the subcategory is ", subCategories);

  useEffect(() => {
    if (id) {
      getSubCategoriesByCategoryId();
    }
  }, [id]);

  const handleSubcategoryProduct = (subCategoryId) => {
    navigate(`/subcategory/products/${subCategoryId}`);
  };
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Subcategories</h1>
      {subCategories.length > 0 ? (
        <div className="grid sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-7 gap-4">
          {subCategories.map((subCategory) => (
            <div
              key={subCategory.id}
              onclick={() => handleSubcategoryProduct(subCategory.id)}
              className="border  rounded-lg shadow-md sm:w-[80px] sm:h-[150px] lg:w-[200px] lg:h-[250px]"
            >
  <Link to={`/subcategory/products/${subCategory.id}`}>
              <img
                src={`https://uvfolderking.com/admin/storage/app/public/${subCategory.image}`}
                alt={subCategory.name}
              />
              <h2 className="flex items-center justify-center text-lg  font-medium text-gray-900">
                {subCategory.name}
              </h2>
</Link>
            </div>
          ))}
        </div>
      ) : (
        <p>No subcategories available for this category.</p>
      )}
    </div>
  );
};

export default SubCategoryListing;
