import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { addproduct,getCartItems } from "../../redux/action";
import { Link } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTag, FaShoppingCart } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

const ProductCard = () => {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const productss = useSelector((state) => state.products);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  console.log("the redux product is ", productss);
  console.log("the redux cart is ", cart);

  useEffect(() => {
    dispatch(addproduct());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  // const fetchProducts = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/get`
  //     );
  //     console.log("the data is response.data for product",response.data);
  //     setProducts(response.data); // Ensure to access data property
  //   } catch (error) {
  //     console.error("Error fetching to get products", error);
  //   }
  // };

  //Add to cart functinality
  const handleAddToCart = async (productId, venderId) => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    if (!token) {
      alert("You need to log in to add items to the cart.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_PUBLIC_API_BASE_URL
        }/cart/user_id_${userId}/vendor_id_${venderId}/product_id_${productId}?t=${new Date().getTime()}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert("Product added to cart successfully!");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      setError("Failed to add product to cart");
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchProducts();
  // }, []);

  const getVisibleProducts = () => {
    return productss.slice(0, 8);
  };

  const visibleProducts = getVisibleProducts();

  const filterArrayProduct = (arr) => {
    return arr.map((product) => {
      let prices = product.prices;
      let x;

      if (prices.length === 1) {
        x = prices[0];
      } else {
        x = prices.reduce((prev, curr) =>
          prev.sale_price > curr.sale_price ? prev : curr
        );
      }

      // Create a new object with the additional `lowprice` field without mutating the original state
      return {
        ...product,
        lowprice: x,
      };
    });
  };

  const productPriceArray = filterArrayProduct(visibleProducts);

  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-gray-600" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-gray-600" />;
      } else {
        return <FaRegStar key={index} className="text-gray-600" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  return (
    <div className="bg-green-50 mx-auto p-2 sm:p-4 md:p-6 lg:p-10 xl:p-10 xl:pt-2">
      <div className="flex text-center justify-between items-center mb-4">
        <p className="font-bold text-xl">Feature</p>
        <Link to="/products">
          <button
            className="text-blue-500 items-center space-x-2 p-2 rounded-lg hover:bg-blue-100 hover:text-black"
            // Optionally, you can toggle showAll here if you want to implement that functionality
          >
            See all
          </button>
        </Link>
      </div>
      <div
        className={`grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6  ${
          !showAll ? "xl:grid-cols-4" : "xl:grid-cols-4"
        }`}
      >
        {productPriceArray.map((product, index) => (
          <div
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-105"
          >
            <Link to={`/products/${product.id}`}>
              <div className="relative">
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  // Set height for the image
                  className="object-cover w-full h-48"
                />
                <img
                  src={`https://uvfolderking.com/suresop/storage/app/public/${product.featured_image}`}
                  alt={product.name}
                  className="absolute inset-0 object-cover w-full h-48 opacity-0 group-hover:opacity-100 transform transition-transform duration-1000 ease-in-out hover:scale-110"
                />
                <div className="absolute top-1 left-1 bg-blue-500 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <FaTag className="mr-1" /> Discount
                </div>
                {/* <div className="absolute top-1 right-1 text-white px-2 py-1 rounded-full text-xs flex items-center">
                  <AiOutlineHeart className="w-5 h-5 inline text-red-500" />
                </div> */}
              </div>
              <div className="p-4">
                <p className="text-start text-lg font-semibold mb-2">
                  {product.name}
                </p>
                <p className="text-sm text-gray-600 mb-2 sm:mb-1">
                  {product.description.slice(0, 50)}
                </p>
                <div className="text-sm text-gray-600 mb-2 sm:mb-1">
                  {renderStars(product.rating)}
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2 mb-2 sm:mb-1">
                    <span className="text-sm font-bold text-green-500">
                      ₹{product.lowprice.sale_price}
                    </span>
                    <span className="text-sm text-gray-500 line-through">
                      ₹{product.lowprice.old_price}
                    </span>
                  </div>
                  {/* <button onClick={()=>handleAddToCart(product.id,product.vendor_id)} className="bg-blue-500 text-white py-2 rounded-lg text-center w-full flex items-center justify-center hover:bg-blue-300">
                    <FaShoppingCart className="mr-2" /> Add to Cart
                  </button> */}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductCard;
