import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { FaTag, FaShoppingCart } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import image from "../images/th.jpeg";
import himage from "../images/hImage.jpg";

const TrendingOffers = () => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const products = [
    {
      id: 1,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 2,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 3,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
    {
      id: 4,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 5,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 6,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 7,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
    {
      id: 8,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 9,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
    {
      id: 10,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 11,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 12,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
    {
      id: 13,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 14,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 15,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
    {
      id: 16,
      label: "Mobile",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A smartphone with cutting-edge technology.",
      rating: 4.5,
    },
    {
      id: 17,
      label: "Tablet",
      image: image,
      himage: himage,
      MRP: 1234,
      DPrice: 234,
      description: "A high-quality tablet with excellent performance.",
      rating: 3.0,
    },
    {
      id: 18,
      label: "Laptop",
      image: image,
      himage: himage,
      MRP: 1233,
      DPrice: 234,
      description: "A powerful laptop for all your computing needs.",
      rating: 5.0,
    },
  ];

  const getVisibleProducts = () => {
    if (showAll) return products;
 
    return products.slice(0, 8);
  };

  const visibleProducts = getVisibleProducts();

  const renderStars = (rating, maxRating = 5) => {
    const stars = Array.from({ length: maxRating }, (_, index) => {
      const currentRating = index + 1;
      if (rating >= currentRating) {
        return <FaStar key={index} className="text-gray-600" />;
      } else if (rating >= currentRating - 0.5) {
        return <FaStarHalfAlt key={index} className="text-gray-600" />;
      } else {
        return <FaRegStar key={index} className="text-gray-600" />;
      }
    });
    return <div className="flex">{stars}</div>;
  };

  // Handle click event to navigate to another page
  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="mx-auto bg-green-50 p-2 sm:p-4 md:p-6 lg:p-10 xl:p-10 xl:pt-2">
      <div className="flex text-center justify-between items-center mb-4">
        <p className="font-bold text-xl">Trending offers</p>
        <button
          className="text-blue-500 items-center space-x-2 p-2 rounded-lg hover:bg-blue-100 hover:text-black"
          // onClick={handleSeeAllClick}
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? "Show Less" : "See All"}
        </button>
      </div>
      <div
        className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ${
          !showAll ? "xl:grid-cols-4" : "xl:grid-cols-4"
        }`}
      >
        {visibleProducts.map((product) => (
          <div
            key={product.id}
            onClick={() => handleProductClick(product.id)}
            className="group relative bg-white shadow-sm border-2 rounded-lg overflow-hidden transform transition-transform duration-700 ease-in-out hover:scale-110"
          >
            <div className="relative">
              <img
                src={product.image}
                alt={product.label}
                className="object-cover w-full h-48  "
              />
              <img
                src={product.himage}
                alt={product.label}
                className="absolute inset-0 object-cover w-full h-48 opacity-0 group-hover:opacity-100 transform transition-transform duration-1000 ease-in-out hover:scale-110"
              />
              <div className="absolute top-1 left-1 bg-blue-500 text-white px-2 py-1 rounded-full text-xs flex items-center">
                <FaTag className="mr-1" /> Discount
              </div>
              <div className="absolute top-1 right-1 text-white px-2 py-1 rounded-full text-xs flex items-center">
                <AiOutlineHeart className="w-5 h-5 inline text-red-500" />
              </div>
            </div>
            <div className="p-4">
              <p className="text-start text-lg font-semibold mb-2">
                {product.label}
              </p>
              <p className="text-sm text-gray-600 mb-2">
                {product.description}
              </p>
              <div className="text-sm text-gray-600 mb-2">
                {renderStars(product.rating)}
              </div>
              <div className="flex flex-col ">
                <div className="flex items-center space-x-2 mb-2">
                  <span className="text-lg font-bold text-green-500">
                    ₹{product.MRP}
                  </span>
                  <span className="text-sm text-gray-500 line-through">
                    ₹{product.DPrice}
                  </span>
                </div>
                <button className="bg-blue-500 text-white py-2 rounded-lg text-center w-full flex items-center justify-center hover:bg-blue-300">
                  <FaShoppingCart className="mr-2" /> Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingOffers;
