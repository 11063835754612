import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import image from "../images/image1.jpg";
import Categories from "./Categories";

const StoreDetail = () => {
  const { id } = useParams();
  const [shop, setShop] = useState(null);

  const fetchStore = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/user/shops/shop_id_${id}`
      );
      setShop(response.data.shop);
    } catch (error) {
      console.error("Error in fetching stores", error);
    }
  };

  useEffect(() => {
    fetchStore();
  }, [id]);

  if (!shop) {
    return <p className="text-center text-xl font-medium mt-10">Loading...</p>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 mt-5">
      <div className="flex flex-col items-center lg:flex-row lg:space-x-8 mt-10">
        {/* Image Section */}
        <div className="relative w-full lg:w-1/2 h-[250px] lg:h-[400px] mb-8 lg:mb-0">
          <img
            src={image}
            alt="Vendor"
            className="rounded-2xl border-2 border-gray-300 shadow-lg object-cover w-full h-full"
          />
        </div>

        {/* Store Info Section */}
        <div className="flex flex-col lg:w-1/2 space-y-6">
          {/* Shop Name and Category */}
          <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-4 lg:mb-0">
              {shop.shop_name}
            </h2>
            <p className="text-lg font-semibold text-gray-700">
              Shop Category: {shop.shop_category}
            </p>
          </div>

          {/* Ratings and Description */}
          <div className="text-lg text-gray-500">
            <p className="font-semibold">Ratings: ★★★★☆</p>
            <p className="mt-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              vulputate, lorem eget vehicula fermentum, purus nisi cursus eros,
              ut gravida ligula mauris eu odio.
            </p>
          </div>

          {/* Contact Section */}
          <div className="p-4 border-t border-b border-gray-200">
            <address className="not-italic text-gray-700">
              <p className="font-bold">Contact us at:</p>
              <p className="mt-2">
                <a
                  href="mailto:contact@example.com"
                  className="text-blue-500 hover:underline"
                >
                  contact@example.com
                </a>
                <br />
                Phone: (123) 456-7890
                <br />
                123 Example Street
                <br />
                {shop.shop_address}
              </p>
            </address>
          </div>
        </div>
      </div>

      {/* Categories Section */}
      <div className="mt-10">
        <Categories />
      </div>
    </div>
  );
};

export default StoreDetail;
