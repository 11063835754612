import React,{useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserAccount from "./UserAccount";
import {Link} from "react-router-dom";

import { MdOutlineMyLocation } from "react-icons/md";

const UserAddressForm = () => {
    const navigate=useNavigate();
    const userId=localStorage.getItem('userId');
    const token = localStorage.getItem('token')
    const [formData, setFormData] = useState({
        full_name: "",
        mobile_number: "",
        pin_code: "",
        full_address: "",
        state: "",
        city: "",
        near_by_address: "",
        alternate_mobile_number: "",
        deliveryOption: "home",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
console.log("formstate",formData);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let errors = {};
        if (!formData.full_name) errors.full_name = "Full name is required.";
        if (!/^[\d]{10}$/.test(formData.mobile_number)) errors.mobile_number = "Valid mobile number is required.";
        if (!formData.pin_code) errors.pin_code = "Pin code is required.";
        if (!formData.full_address) errors.full_address = "Address is required.";
        return errors;
    };

    
      

    const handleSubmit = async(e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // setLoading(true);
        // setSuccessMessage("");


        try{
            const response=await axios.post(`https://uvfolderking.com/suresop/api/user/user_id_${userId}/shipping-address`,formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            navigate('/account/addresses');
           
                    }catch(error){
                        console.error('the error in adding addres',error);
                    }
                
     
            // setLoading(false);
            // setSuccessMessage("Form submitted successfully!");
            setFormData({
                full_name: "",
                mobile_number: "",
                pin_code: "",
                full_address: "",
                state: "",
                city: "",
                near_by_address: "",
                alternate_mobile_number: "",
                deliveryOption: "home",
            });
       
    };

    const handleLocationClick = () => {
        console.log("Using current location...");
    };

  return (
    <div>
      <div className="flex gap-5 lg:ml-10 ">
        <div>
          <UserAccount />
        </div>
        <div className="flex-grow p-5 mr-10 shadow-lg rounded-md">
          <h2 className="text-xl font-semibold pb-5">ADD NEW ADDRESS</h2>
          
         
            <div className="flex justify-center items-center h-screen bg-gray-100">
            <div className="w-full max-w-xl p-8 bg-white shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-xl font-bold">Add Address</h2>
                    <button
                        onClick={handleLocationClick}
                        className="flex items-center bg-blue-500 text-white px-4 py-2 rounded cursor-pointer"
                    >
                        <MdOutlineMyLocation className="text-xl mr-2" />
                        <span>Use your Current Location</span>
                    </button>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-4">
                            <div className="w-full">
                                <input
                                    type="text"
                                    placeholder="Enter Name"
                                    name="full_name"
                                    value={formData.full_name}
                                    onChange={handleChange}
                                    className={`border p-2 w-full rounded ${errors.full_name ? "border-red-500" : "border-gray-300"}`}
                                />
                                {errors.full_name && <p className="text-red-500 text-sm">{errors.full_name}</p>}
                            </div>

                            <div className="w-full">
                                <input
                                    type="text"
                                    placeholder="Mobile No"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleChange}
                                    className={`border p-2 w-full rounded ${errors.mobile_number ? "border-red-500" : "border-gray-300"}`}
                                />
                                {errors.mobile_number && <p className="text-red-500 text-sm">{errors.mobile_number}</p>}
                            </div>
                        </div>

                        <div>
                            <textarea
                                cols={50}
                                rows={4}
                                placeholder="Enter your address"
                                name="full_address"
                                value={formData.full_address}
                                onChange={handleChange}
                                className={`border p-2 w-full rounded ${errors.full_address ? "border-red-500" : "border-gray-300"}`}
                            />
                            {errors.full_address && <p className="text-red-500 text-sm">{errors.full_address}</p>}
                        </div>

                        <input
                            type="text"
                            placeholder="Landmark"
                            name="near_by_address"
                            value={formData.near_by_address}
                            onChange={handleChange}
                            className="border p-2 w-full rounded border-gray-300"
                        />

                        <div className="flex gap-4">
                            <input
                                type="text"
                                placeholder="City/District/Town"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                className="border p-2 w-full rounded border-gray-300"
                            />
                            <input
                                type="text"
                                placeholder="State"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                                className="border p-2 w-full rounded border-gray-300"
                            />
                        </div>

                        <div className="flex gap-4">
                            <input
                                type="text"
                                placeholder="PinCode"
                                name="pin_code"
                                value={formData.pin_code}
                                onChange={handleChange}
                                className="border p-2 w-full rounded border-gray-300"
                            />
                            <input
                                type="text"
                                placeholder="Alternate Phone (optional)"
                                name="alternate_mobile_number"
                                value={formData.alternate_mobile_number}
                                onChange={handleChange}
                                className="border p-2 w-full rounded border-gray-300"
                            />
                        </div>

                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded font-bold"
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Deliver Here"}
                        </button>
                      
                        {/* {successMessage && <p className="text-green-500 mt-2">{successMessage}</p>} */}
                    </div>
                </form>
            </div>
        </div>
           
        </div>
      </div>
    </div>
  );
};

export default UserAddressForm;
