import React, { useState, useEffect } from "react";
import { IoCartSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch, FaUser } from "react-icons/fa";
import {
  FiUser,
  FiShoppingCart,
  FiPackage,
  FiHeart,
  FiLogOut,
} from "react-icons/fi";
import { FaShop } from "react-icons/fa6";
import { IoLogoAndroid } from "react-icons/io";
import { IoIosAppstore } from "react-icons/io";
import { MdInstallMobile } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

const Navbar = () => {
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);

  const token = localStorage.getItem("token");
  const userName = localStorage.getItem("userName");
  const totalQuantity = useSelector((state) => state.totalQuantity);

  return (
    <nav className="navbar  sticky top-0 z-50 bg-blue-700 mt-0 pt-1 pb-1 ">
      <div className="navbar-container flex flex-wrap justify-between items-center m-1 md:m-3 ">
        {/* Logo */}
        <div className="flex-shrink-0 text-[30px]">
          <Link
            to="/"
            className="navbar-logo  sm:text-3xl text-white font-bold sm:pl-4 md:pl-6 lg:ml-2 xl:ml-3"
          >
            Suresop
          </Link>
        </div>
        <div className="flex justify-between  justify-center items-center text-white font-bold text-2xl ml-14 cursor-pointer">
          <a
            href="https://vendorsure.suresop.com/ "
            target="_blank"
            rel="noreferrer"
            className="flex items-center   "
          >
            <div>
              <FaShop size={26} className="font-bold" />
            </div>

            <div className="ml-2 text-[24px] font-semibold text-white hidden lg:block ">
              Become Seller
            </div>
          </a>
        </div>
        {/* Search Bar Container */}
        <div className="flex-grow  flex justify-center items-center hidden lg:block lg:ml-10 ">
          {/* Full Search Bar for larger screens */}
          <div className=" hidden sm:inline  bg-white sm:flex items-center border-2 border-black-300 rounded-2xl p-1 w-full max-w-2xl">
            <input
              type="text"
              placeholder="Searching Products..."
              className="search-input text-black p-2 ml-5 border-none w-full outline-none text-sm sm:text-base"
            />
            <FaSearch className="mr-5 text-blue-500 " />
          </div>

          {/* Search Icon for mobile view */}
          <div className="sm:hidden flex items-center">
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="text-blue-500 p-2 hidden sm:inline "
            >
              <FaSearch size={24} />
            </button>
          </div>
        </div>

        <div className="flex flex-col mr-18 cursor-pointer  hidden sm:inline">
          <Link to="/account/myorders">
            <p className="text-white ">Returns</p>
            <p className="text-white font-semibold">& Orders </p>
          </Link>
        </div>
        {/* Icons */}
        <div className="flex-shrink-0 flex items-center space-x-3 lg:pr-5 sm:ml-5 ">
          <div className="relative group ">
            <div className=" flex items-center space-x-2 text-white rounded-lg p-2 hover:bg-white hover:text-blue-500 text-sm sm:text-base">
              <MdInstallMobile size={24} className="cursor-pointer " />
            </div>
            {/* Dropdown */}
            <div className="absolute top-full z-50 left-0 mt-1 lg:w-55 bg-white shadow-lg rounded-lg py-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300 invisible group-hover:visible ">
              <a
                href="https://play.google.com/store"
                target="_blank"
                rel="noreferrer"
                className="flex items-center px-4 py-2 hover:bg-blue-100 text-gray-800  "
              >
                <IoLogoAndroid className="mr-2 text-green-500" size={20} />
                <p className="hidden sm:inline ">Android </p>
              </a>
              <a
                href="https://www.apple.com/app-store/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center px-4 py-2 hover:bg-blue-100 text-gray-800"
              >
                <IoIosAppstore className="mr-2 text-blue-500" size={20} />
                <p className="hidden sm:inline ">ios </p>
              </a>
            </div>
          </div>
          <div className="relative group">
            <Link
              to={token ? "/account/account_details" : "/login-user"}
              className=" flex items-center space-x-2 text-white p-2 rounded-lg hover:bg-white hover:text-blue-500 text-sm sm:text-base "
            >
              <FaUser size={20} className="" />
              <span className="hidden md:inline ">
                {token ? userName : "Login"}
              </span>
            </Link>

            {/* Dropdown */}

            {token && (
              <div
                className="absolute top-full left-0 mt-0 lg:w-40 bg-white shadow-lg rounded-lg py-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 invisible group-hover:visible  "
                style={{ zIndex: 9999999999 }}
              >
                <Link
                  to="/account/account_details"
                  className="block px-4 py-2 hover:bg-blue-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiUser className=" text-blue-500  text-xl" />
                  <span>Profile</span>
                </Link>
                <Link
                  to="/cart"
                  className="block px-4 py-2 hover:bg-blue-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiShoppingCart className="text-green-500  text-xl" />
                  <span>Cart</span>
                </Link>
                <Link
                  to="/account/myorders"
                  className="block px-4 py-2 hover:bg-blue-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiPackage className="text-xl text-yellow-500" />
                  <span>Orders</span>
                </Link>
                <Link
                  to="/account/wishlist-products"
                  className="block px-4 py-2 hover:bg-blue-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiHeart className="text-xl text-red-500" />
                  <span>Wishlist</span>
                </Link>

                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    // window.location.reload();
                    navigate("/");
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-blue-100 text-gray-800 flex items-center space-x-2"
                >
                  <FiLogOut className="text-xl text-gray-500 " />
                  <span>Sign Out</span>
                </button>
              </div>
            )}
          </div>

          <Link
            to="/cart"
            className="flex items-center space-x-2 p-2 text-white rounded-lg hover:bg-white hover:text-blue-500 relative"
          >
            <IoCartSharp size={24} />
            {/* <span className="hidden md:inline text-sm sm:text-base">Cart</span> */}

            {/* Badge for total quantity */}

            {totalQuantity >= 0 && (
              <span className="absolute top-0 right-0 inline-block h-5 w-5 rounded-full bg-red-600 text-white text-xs font-bold flex items-center justify-center">
                {totalQuantity}
              </span>
            )}
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
